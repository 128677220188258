<template>
  <div class="sidebar" :class="{ resize: resizeSidebar }">
    <button @click="hide_nav()" class="toggle_button">
      <i class="fas fa-arrow-circle-right arrow"></i>
    </button>

    <div class="scrollTestng">
      <div
        v-for="(item, index) in allPermission"
        :key="index"
        class="sidebar_items pr-2"
      >
        <!-- <div class="my-dropdown" v-if="item.info">
          <div>
            <div class="selected d-flex" @click="toggle(index)">
              <i :class="item.icon"></i>
              <p>{{ item.name }}</p>
            </div>
            <div class="options" v-show="isOpen === index" v-if="isOpen">
              <div
                class="option"
                @click="set(info.name)"
                v-for="info in item.info"
                :key="info.id"
              >
                <router-link :to="info.path" class="d-flex">
                  <i v-if="!info.icon" :class="item.icon"></i>
                  <i :class="info.icon"></i>
                  <p>{{ info.title }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div> -->
        <div v-if="item.path">
          <router-link :to="item.path" class="d-flex">
            <i :class="item.icon"></i>
            <p>{{ item.title }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
export default {
  name: "sidebar",
  data() {
    return {
      isOpen: false,
      selected: "Select your favourite beer",
      resizeSidebar: false,
        items: [
        {
          name: "csr",
          title: this.$t("admin_sidebar.csr"),
          path: "/adminpage/addcsr",
          icon: "fas fa-hands-helping",
        },
        {
          name: "news",
          title: this.$t("admin_sidebar.news"),
          path: "/adminpage/addNews",
          icon: "fas fa-newspaper",
        },
        {
          name: "blog",
          title: this.$t("admin_sidebar.blog"),
          path: "/adminpage/addBlogs",
          icon: "fas fa-keyboard",
        },
        {
          name: "blogInfo",
          title: this.$t("admin_sidebar.blogInfo"),
          path: "/adminpage/addBlogInfo",
          icon: "fas fa-keyboard",
        },
        {
          name: "team",
          title: this.$t("admin_sidebar.team"),
          path: "/adminpage/addteam",
          icon: "fas fa-users",
        },
        {
          name: "teamInfo",
          title: this.$t("admin_sidebar.teamInfo"),
          path: "/adminpage/addteamInfo",
          icon: "fas fa-users",
        },
        {
          name: "exclusive_brands",
          title: this.$t("admin_sidebar.exclusive_brands"),
          path: "/adminpage/addExclusiveBrands",
          icon: "fas fa-pills",
        },
        {
          name: "category",
          title: this.$t("admin_sidebar.category"),
          path: "/adminpage/addCategory",
          icon: "fas fa-pills",
        },
        {
          name: "partners",
          title: this.$t("admin_sidebar.partners"),
          path: "/adminpage/addPartners",
          icon: "fas fa-handshake",
        },
        {
          name: "partners_info",
          title: this.$t("admin_sidebar.partners_info"),
          path: "/adminpage/addPartnersInfo",
          icon: "fas fa-handshake",
        },
        {
          name: "career",
          title: this.$t("admin_sidebar.career"),
          path: "/adminpage/addcareer",
          icon: "fas fa-user-chart",
        },
        {
          name: "careerInfo",
          title: this.$t("admin_sidebar.careerInfo"),
          path: "/adminpage/addCareerInfo",
          icon: "fas fa-user-chart",
        },
        {
          name: "career_category",
          title: this.$t("admin_sidebar.career_category"),
          path: "/adminpage/addCareerCategory",
          icon: "fas fa-chart-line",
        },
        {
          name: "main_hero",
          title: this.$t("admin_sidebar.main_hero"),
          path: "/adminpage/addHero",
          icon: "fas fa-pager",
        },
        {
          name: "main_page_cards",
          title: this.$t("admin_sidebar.main_page_cards"),
          path: "/adminpage/addCards",
          icon: "fas fa-th-large",
        },
        {
          name: "company",
          title: this.$t("admin_sidebar.company"),
          path: "/adminpage/addcompany",
          icon: "fas fa-building",
        },
        {
          name: "values",
          title: this.$t("admin_sidebar.values"),
          path: "/adminpage/addvalues",
          icon: "fas fa-hands-heart",
        },
        {
          name: "history",
          title: this.$t("admin_sidebar.history"),
          path: "/adminpage/addhistory",
          icon: "fas fa-history",
        },
        {
          name: "distribution",
          title: this.$t("admin_sidebar.distribution"),
          path: "/adminpage/adddistribution",
          icon: "fas fa-truck-moving",
        },
        {
          name: "video",
          title: this.$t("admin_sidebar.video"),
          path: "/adminpage/addvideo",
          icon: "fas fa-video",
        },
        {
          name: "privacy",
          title: this.$t("admin_sidebar.privacy"),
          path: "/adminpage/addPrivacy",
          icon: "fas fa-user-secret",
        },
        {
          name: "legal",
          title: this.$t("admin_sidebar.legal"),
          path: "/adminpage/addLegal",
          icon: "fas fa-balance-scale-left",
        },
        {
          name: "contact",
          title: this.$t("admin_sidebar.contact"),
          path: "/adminpage/addContact",
          icon: "fas fa-phone-square-alt",
        },
        {
          name: "contactInfo",
          title: this.$t("admin_sidebar.contactInfo"),
          path: "/adminpage/addContactInfo",
          icon: "fas fa-phone-square-alt",
        },
        {
          name: "confidentialEmail",
          title: this.$t("admin_sidebar.confidentialEmail"),
          path: "/adminpage/addEmail",
          icon: "fas fa-phone-square-alt",
        },
        {
          name: "user",
          title: this.$t("admin_sidebar.user"),
          path: "/adminpage/addUser",
          icon: "fas fa-user",
        },
        {
          name: "shop",
          title: this.$t("admin_sidebar.sticky_button"),
          path: "/adminpage/addStickyButton",
          icon: "fas fa-shopping-cart",
        },
      ],
      allPermission: [],
    };
  },
  created() {
    const decodeData = jwt_decode(localStorage.getItem("access_token"));
    this.items.map((item) => {
      if (decodeData.permissions.length > 0) {
        decodeData?.permissions?.map((secondItem) => {
          if (item.name === secondItem.permission) {
            this.allPermission.push(item);
          }
        });
      } else {
        this.allPermission.push(item);
      }
    });
    if (localStorage.getItem("sidebar_status") === "true") {
      this.resizeSidebar = true;
    }
  },
  methods: {
    hide_nav() {
      this.resizeSidebar = !this.resizeSidebar;
      localStorage.setItem("sidebar_status", this.resizeSidebar);
    },
    toggle: function (index) {
      if (this.isOpen !== null) {
        this.isOpen = this.isOpen == index ? null : index;
      } else {
        this.isOpen = index;
      }
    },
    show: function () {
      this.isOpen = true;
    },
    hide: function () {
      this.isOpen = false;
    },
    set: function (option) {
      this.selected = option;
      this.hide();
    },
  },
};
</script>

<style scoped>
.my-dropdown {
  cursor: pointer;
}
.selected {
  height: 40px;
  padding: 7px;
}
.selected:after {
  margin-left: 10px;
}
.selected p {
  font-size: 0.8rem;
}
.options {
  margin: 2px 0 0 10px;
}
.option {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.option:hover {
  background-color: #c9c3c7;
  border-radius: 5px;
}
.option:last-child {
  border-bottom: none;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #7e549374;
}
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.scrollTestng {
  min-height: calc(100vh - 240px);
  max-height: calc(100vh - 180px) !important;
  overflow-y: scroll;
}
.fas {
  color: #7e5493;
}
.sidebar .sidebar_items a.router-link-exact-active {
  background: #c9c3c7;
  border-radius: 5px;
  cursor: pointer;
}
.sidebar a {
  padding: 6px;
}
.sidebar {
  width: 100px;
  padding: 10px;
  background: #eee9f0;
  border: 1px solid #dadada;
  transition: 0.3s;
}
.toggle_button {
  display: block;
  margin: 0 0 10px auto;
  background: none;
  border: none;
}
.resize {
  width: 280px;
  transition: 0.3s;
}
.resize p,
.dropdown {
  display: block !important;
  width: 0;
}
.resize .arrow {
  transform: rotate(180deg);
}
.sidebar_items {
  padding-bottom: 5px;
}
.sidebar i {
  margin-right: 10px;
  font-size: 20px;
}
.sidebar p {
  width: 100%;
  transition: 0.3s;
  margin: 0px;
  letter-spacing: 1px;
  font-family: mtavruliBOLD;
  color: #666666;
  text-transform: uppercase;
}
.sidebar a {
  color: black;
  text-decoration: none;
  overflow: hidden;
  min-width: 26px;
  font-size: 0.8rem;
}
.sidebar p,
.dropdown {
  display: none;
}
@media all and (max-width: 992px) {
  .sidebar {
    display: none;
  }
}
</style>
