<template>
  <div class="admin">
    <div class="header">
      <div class="header_text">
        <router-link to="/adminpage">
          <div class="loginLogo">
            <img src="../../assets/img/logo.png" alt="" />
          </div>
        </router-link>
      </div>
      <div class="button">
        <LanguageSwitcher />
        <button @click="logout">
          <i class="far fa-arrow-alt-square-left"></i>
          <!-- {{ $t("admin.logout") }} -->
        </button>
      </div>
    </div>
    <div class="admin_page">
      <SideBar />
      <div class="main" v-if="path.includes(this.$route.name)">
        <div class="container">
          <div class="progress" v-for="item in totals" :key="item.color">
            <span
              class="title timer"
              data-from="0"
              data-to="85"
              data-speed="1800"
              ><div style="display: flex">
                <div>
                  <p style="font-size: 14px">
                    {{ item.name }}: <span>{{ item.total }}</span>
                  </p>
                </div>
              </div></span
            >
            <div class="overlay"></div>
            <div
              class="left"
              :style="`border: 14px solid ${item.color};border-right: 0`"
            ></div>
            <div
              class="right"
              :style="`border: 14px solid ${item.color};border-right: 0`"
            ></div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../env.json";
import SideBar from "../../components/Navbar/SideBar.vue";
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher.vue";
export default {
  name: "adminpage",
  components: {
    SideBar,
    LanguageSwitcher,
  },
  props: ["name_of_chapter"],

  data() {
    return {
      currentPage: 1,
      perPage: 4,
      totals: [
        { name: "TOTAL CSR", text: "csr", total: "", color: "#987284" },
        { name: "TOTAL BLOG", text: "blog", total: "", color: "#75b9be" },
        { name: "TOTAL NEWS", text: "news", total: "", color: "#d0d6b5" },
        { name: "TOTAL VACANCY", text: "career", total: "", color: "#f9b5ac" },
        {
          name: "TOTAL PARTENRS",
          text: "partners",
          total: "",
          color: "#ee7674",
        },
        {
          name: "TOTAL PRODUCTS",
          text: "products",
          total: "",
          color: "#deb290",
        },
        { name: "TOTAL TEAM", text: "team", total: "", color: "#b17cb1" },
        { name: "TOTAL USERS", text: "users", total: "", color: "#85dc69" },
      ],

      path: ["adminpage"],
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push("/admin"));
    },
    getTotal() {
      axios.get(`${env.host}/get/lengths`).then((result) => {
        this.totals.forEach((_, i) => {
          this.totals[i].total = result.data.item[this.totals[i].text];
        });
        // result.data.item.csr
        // result.data.item.career

        // result.data.item['csr']
        // result.data.item['career']

        // this.totals[i].total = result.data.item[this.totals[i].text]
      });
    },
  },
  mounted() {
    this.getTotal();
  },
};
</script>

<style scoped>
.loginLogo img {
  width: 200px;
}
.admin_page {
  display: flex;
}
.admin {
  margin-top: 0 !important;
}
.header {
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  /* border-bottom: 1px solid #dadada; */
}
.header_text {
  display: flex;
  align-items: center;
}
.header_text a {
  color: black !important;
}
.main {
  width: 100%;
  /* display: flex; */
  justify-content: center;
  margin-top: 50px;
}
.button {
  display: flex;
  margin-left: auto !important;
  margin-right: 0 !important;
}
.button button {
  background: none;
  border: none;
}
.button a {
  color: black;
}
.button a:hover {
  color: royalblue;
  transition: 0.2s;
}
.button i {
  font-size: 20px;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: white;
  /* height: 100vh; */
  align-items: center;
}

.progress {
  width: 200px;
  height: 200px;
  font-size: 30px;
  color: black;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  /* background: #07070c; */
  background: white;
  text-align: center;
  line-height: 200px;
  margin: 20px;
}

.progress .title {
  position: relative;
  z-index: 100;
  margin: auto;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 14px solid #b17cb1;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}
/* .progress .left1,
.progress .right1 {
  border: 14px solid #987284;
  border-right: 0;
}
.progress .left2,
.progress .right2 {
  border: 14px solid #75b9be;
  border-right: 0;
}
.progress .left3,
.progress .right3 {
  border: 14px solid #d0d6b5;
  border-right: 0;
}
.progress .left4,
.progress .right4 {
  border: 14px solid #f9b5ac;
  border-right: 0;
}

.progress .left5,
.progress .right5 {
  border: 14px solid #ee7674;
  border-right: 0;
}

.progress .left6,
.progress .right6 {
  border: 14px solid #deb290;
  border-right: 0;
}

.progress .left7,
.progress .right7 {
  border: 14px solid #85dc69;
  border-right: 0;
} */

.progress .left {
  animation: load1 1s linear forwards;
}

.progress .right,
.progress .right {
  animation: load2 1.5s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(360deg);
  }
}

.loginLogo img {
  width: 200px;
}
.admin_page {
  display: flex;
}
.admin {
  margin-top: 0 !important;
}
.header {
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #dadada;
}
.header_text {
  display: flex;
  align-items: center;
}
.header_text a {
  color: black !important;
}

.button {
  display: flex;
  margin-left: auto !important;
  margin-right: 0 !important;
}
.button button {
  background: none;
  border: none;
}
.button a {
  color: black;
}
.button a:hover {
  color: #b17cb1;
  transition: 0.2s;
}
.button i {
  font-size: 20px;
}
</style>
